@font-face {
	font-family: "DM Sans";
	src: url("../statics/DMSans-VariableFont_opsz,wght.ttf");
}

:root {
	--prim-bg-light: #3c3f4d;
	--prim-bg-dark: #2b2d36;
	--sec-col-light: #ded5c6;
	--sec-col-dark: #eea545;
	--sec-col-dark-hover: #ee9535;
	--sec-col-dark-deactivated: #be7515;
}

* {
	margin: 0;
	box-sizing: border-box;
}

:host,
body,
html {
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-moz-tab-size: 4;
	tab-size: 4;
	font-family: "DM Sans", ui-sans-serif, system-ui, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	color: #f8fafc;
	font-feature-settings: normal;
	font-variation-settings: normal;
	-webkit-tap-highlight-color: transparent;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	overflow-y: scroll;
}

body {
	background-color: var(--prim-bg-light);
	text-align: center;
	font-size: 1.25rem;
	position: absolute;
	width: 100svw;
}

section {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--prim-bg-dark);
	min-height: 110vh;
}

section:nth-of-type(1) {
	padding-top: 4rem;
}

h1 {
	text-wrap: wrap;
	padding-bottom: 3.5rem;
	font-size: clamp(2rem, -0.33rem + 12vw, 4.5rem);
	line-height: 1;
	font-weight: 700;
	color: var(--sec-col-dark);
}

#home > .content > h1 {
	color: #f8fafc;
}

h2 {
	text-wrap: wrap;
	padding-bottom: 2rem;
	font-size: 1.875rem;
	line-height: 2.25rem;
	font-weight: 700;
}

@media (min-width: 768px) {
	h2 {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}
}

h3 {
	text-wrap: wrap;
	padding-bottom: 0.5rem;
	padding-top: 2.5rem;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 700;
}

@media (min-width: 768px) {
	h3 {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

ul {
	text-align: left;
	list-style-type: disc;
	list-style-position: outside;
}

a {
	text-decoration: underline;
	color: #f8fafc;
}

a:hover {
	text-decoration: none;
}

.prim-bg-light {
	background-color: var(--prim-bg-light);
}

.prim-bg-dark {
	background-color: var(--prim-bg-dark);
}

.sec-col-light {
	background-color: var(--sec-col-light);
}

.sec-col-dark {
	background-color: var(--sec-col-dark);
}

.bg-mts-prim-2:hover {
	background-color: var(--sec-col-dark-hover);
}

.block {
	text-align: justify;
}

.rounded-xl {
	border-radius: 0.75rem;
}

.font-weight-600 {
	font-weight: 600;
}

.font-weight-700 {
	font-weight: 700;
}

.pb-s {
	padding-bottom: 1rem;
}

.p-m {
	padding: 2.5rem;
}

.pb-l {
	padding-bottom: 3.5rem;
}

.pt-s {
	padding-top: 1rem;
}

.pt-l {
	padding-top: 3.5rem;
}

.h-center {
	margin-left: auto;
	margin-right: auto;
}

.max-w-s {
	max-width: 6rem;
}

.text-start {
	text-align: start;
}

.memberships {
	display: grid;
	padding-top: 2.5rem;
	padding-inline-start: 1.5rem;
	padding-inline-end: 1.5rem;
	gap: 2rem;
	text-align: start;
}

@media (min-width: 1025px) {
	.memberships {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 1024px) {
	.memberships {
		grid-template-rows: 1fr 1fr;
	}
}

.membership {
	border-radius: 0.75rem;
}

.membership-btn {
	text-align: center;
	text-decoration: none;
}

.membership-btn > div {
	margin-top: 1.5rem;
	padding: 0.75rem;
	border-radius: 0.375rem;
	color: #1f2937;
	background-color: var(--sec-col-dark);
}

.membership-btn > div:hover {
	background-color: var(--sec-col-dark-hover);
}

.membership-btn-deactivated {
	text-align: center;
	text-decoration: none;
}

.membership-btn-deactivated > div {
	margin-top: 1.5rem;
	padding: 0.75rem;
	border-radius: 0.375rem;
	color: #1f2937;
	background-color: var(--sec-col-dark-deactivated);
}

.btn {
	text-align: center;
	text-decoration: none;
	margin-top: 1.5rem;
	padding: 0.75rem;
	border-radius: 0.375rem;
	color: #1f2937;
	background-color: var(--sec-col-dark);
}

.header {
	z-index: 50;
	position: fixed;
	top: 0;
	left: 0;
	display: grid;
	grid-template-columns: max-content max-content auto max-content;
	gap: 0.75rem;
	background-color: var(--prim-bg-light);
	text-align: start;
	height: 5rem;
	width: 100%;
	align-items: center;
	padding-inline-start: 2rem;
	padding-inline-end: 2rem;
	font-size: 2rem;
	line-height: 1.75rem;
	font-weight: 700;
}

.header > .icon {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.header > a {
	font-size: 0.7rem;
}

@media (max-width: 1024px) {
	.header > .icon {
		max-width: 3rem;
	}
}

@media (max-width: 768px) {
	.header {
		text-align: center;
		justify-content: center;
		height: auto;
		padding: 0.25rem;
		font-size: 1.75rem;
	}
}

.officeIcons {
	display: flex;
	flex-direction: row;
	gap: 1.5rem;
	padding-bottom: 1rem;
	flex-grow: 1;
	justify-content: space-around;
}

.officeIcons > img {
	flex-grow: 1;
	max-width: 130px;
}

.planGrid {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 1.5rem;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	justify-items: center;
}

.planGrid > img {
	width: clamp(4rem, 10vw, 6rem);
	max-width: 6rem;
}

.planGrid > div {
	font-size: clamp(1rem, 2vw, 1.25rem);
	text-align: center;
}

.content {
	margin-left: auto;
	margin-right: auto;
	max-width: min(65ch, 100svw);
	padding-top: 5rem;
	padding-bottom: 5rem;
	padding-inline-start: 1rem;
	padding-inline-end: 1rem;
}

#home > .content {
	padding-top: 1rem;
}

.content > .table {
	display: flex;
	flex-direction: row;
	gap: 5rem;
}

.content > .table > div {
	width: 32ch;
	min-width: 32ch;
	text-align: start;
}

.content > .table > img {
	width: 32ch;
	height: auto;
}

@media (max-width: 768px) {
	.content > .table > div {
		width: 90vw;
		max-width: 65ch;
		text-align: justify;
	}

	.content > .table > img {
		display: none;
	}
}

.max-w-screen-xl {
	max-width: 1280px;
}

.text-small {
	font-size: 1.225rem;
	line-height: 1.75rem;
}

.text-medium {
	font-size: 1.5rem;
	line-height: 2rem;
}

.text-large {
	font-size: 1.875rem;
	line-height: 2.25rem;
}

.text-gray {
	color: #1f2937;
}

.text-lightgray {
	color: #888;
}

.text-black {
	color: #000;
}

@keyframes scroll-right {
	0% {
		opacity: 0;
		transform: translateX(-10%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.move_right {
	opacity: 0;
	transform: translateX(-10%);
	animation: scroll-right 1s both;
}

.move_right:nth-child(2) {
	animation-delay: 0.2s;
}

.move_right:nth-child(3) {
	animation-delay: 0.4s;
}

.move_right:nth-child(4) {
	animation-delay: 0.6s;
}

@keyframes fly-right {
	from {
		opacity: 0;
		transform: translateX(-10vw);
	}

	to {
		opacity: 1;
		transform: translateX(0);
	}
}

.text-slider {
	color: var(--sec-col-dark);
}

.text-slider span {
	display: none;
}

.text-slider:after {
	content: "Microsoft Excel";
	animation: spin 5s linear infinite;
}
@keyframes spin {
	0% {
		content: "Microsoft Excel";
	}
	25% {
		content: "Microsoft Word";
	}
	50% {
		content: "Google Sheets";
	}
	75% {
		content: "Google Docs";
	}
}

.strikethrough {
	text-decoration: line-through;
}

.underline {
	text-decoration-line: underline;
}

footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	background-color: #3c3e47;
	box-shadow: 0px 0px 2px gray;

	padding-bottom: 1rem;
	padding-top: 1rem;

	margin-bottom: 1rem;
	margin-top: 1rem;
	margin-left: auto;
	margin-right: auto;

	align-self: center;
	justify-self: center;
	align-content: center;
	justify-items: center;

	width: 90vw;
	max-width: 1024px;

	border: #000;
	border-style: none;
	border-color: gray;
	border-width: 1px;
	border-radius: 18px;

	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

footer .button {
	text-decoration-line: underline;
	text-decoration-thickness: 1px;
}

@media (max-width: 400px) {
	footer {
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
		padding-left: 0.25rem;
		padding-right: 0.25rem;

		margin-bottom: 0rem;
		margin-top: 0rem;

		width: 100vw;

		border-radius: 0px;
	}

	footer .button {
		font-size: 1rem;
	}
}
