@font-face {
  font-family: DM Sans;
  src: url("DMSans-VariableFont_opsz,wght.cac90019.ttf");
}

:root {
  --prim-bg-light: #3c3f4d;
  --prim-bg-dark: #2b2d36;
  --sec-col-light: #ded5c6;
  --sec-col-dark: #eea545;
  --sec-col-dark-hover: #ee9535;
  --sec-col-dark-deactivated: #be7515;
}

* {
  box-sizing: border-box;
  margin: 0;
}

:host, body, html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  color: #f8fafc;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  font-family: DM Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  overflow: hidden scroll;
}

body {
  background-color: var(--prim-bg-light);
  text-align: center;
  width: 100svw;
  font-size: 1.25rem;
  position: absolute;
}

section {
  background-color: var(--prim-bg-dark);
  justify-content: center;
  align-items: center;
  min-height: 110vh;
  display: flex;
}

section:first-of-type {
  padding-top: 4rem;
}

h1 {
  text-wrap: wrap;
  color: var(--sec-col-dark);
  padding-bottom: 3.5rem;
  font-size: clamp(2rem, 12vw - .33rem, 4.5rem);
  font-weight: 700;
  line-height: 1;
}

#home > .content > h1 {
  color: #f8fafc;
}

h2 {
  text-wrap: wrap;
  padding-bottom: 2rem;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

@media (width >= 768px) {
  h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

h3 {
  text-wrap: wrap;
  padding-top: 2.5rem;
  padding-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

ul {
  text-align: left;
  list-style-type: disc;
  list-style-position: outside;
}

a {
  color: #f8fafc;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.prim-bg-light {
  background-color: var(--prim-bg-light);
}

.prim-bg-dark {
  background-color: var(--prim-bg-dark);
}

.sec-col-light {
  background-color: var(--sec-col-light);
}

.sec-col-dark {
  background-color: var(--sec-col-dark);
}

.bg-mts-prim-2:hover {
  background-color: var(--sec-col-dark-hover);
}

.block {
  text-align: justify;
}

.rounded-xl {
  border-radius: .75rem;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.pb-s {
  padding-bottom: 1rem;
}

.p-m {
  padding: 2.5rem;
}

.pb-l {
  padding-bottom: 3.5rem;
}

.pt-s {
  padding-top: 1rem;
}

.pt-l {
  padding-top: 3.5rem;
}

.h-center {
  margin-left: auto;
  margin-right: auto;
}

.max-w-s {
  max-width: 6rem;
}

.text-start {
  text-align: start;
}

.memberships {
  text-align: start;
  padding-top: 2.5rem;
  gap: 2rem;
  padding-inline: 1.5rem;
  display: grid;
}

@media (width >= 1025px) {
  .memberships {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 1024px) {
  .memberships {
    grid-template-rows: 1fr 1fr;
  }
}

.membership {
  border-radius: .75rem;
}

.membership-btn {
  text-align: center;
  text-decoration: none;
}

.membership-btn > div {
  color: #1f2937;
  background-color: var(--sec-col-dark);
  border-radius: .375rem;
  margin-top: 1.5rem;
  padding: .75rem;
}

.membership-btn > div:hover {
  background-color: var(--sec-col-dark-hover);
}

.membership-btn-deactivated {
  text-align: center;
  text-decoration: none;
}

.membership-btn-deactivated > div {
  color: #1f2937;
  background-color: var(--sec-col-dark-deactivated);
  border-radius: .375rem;
  margin-top: 1.5rem;
  padding: .75rem;
}

.btn {
  text-align: center;
  color: #1f2937;
  background-color: var(--sec-col-dark);
  border-radius: .375rem;
  margin-top: 1.5rem;
  padding: .75rem;
  text-decoration: none;
}

.header {
  z-index: 50;
  background-color: var(--prim-bg-light);
  text-align: start;
  grid-template-columns: max-content max-content auto max-content;
  align-items: center;
  gap: .75rem;
  width: 100%;
  height: 5rem;
  padding-inline: 2rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.75rem;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
}

.header > .icon {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.header > a {
  font-size: .7rem;
}

@media (width <= 1024px) {
  .header > .icon {
    max-width: 3rem;
  }
}

@media (width <= 768px) {
  .header {
    text-align: center;
    justify-content: center;
    height: auto;
    padding: .25rem;
    font-size: 1.75rem;
  }
}

.officeIcons {
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-around;
  gap: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
}

.officeIcons > img {
  flex-grow: 1;
  max-width: 130px;
}

.planGrid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  place-items: center;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.planGrid > img {
  width: clamp(4rem, 10vw, 6rem);
  max-width: 6rem;
}

.planGrid > div {
  text-align: center;
  font-size: clamp(1rem, 2vw, 1.25rem);
}

.content {
  padding-top: 5rem;
  padding-bottom: 5rem;
  max-width: min(65ch, 100svw);
  margin-left: auto;
  margin-right: auto;
  padding-inline: 1rem;
}

#home > .content {
  padding-top: 1rem;
}

.content > .table {
  flex-direction: row;
  gap: 5rem;
  display: flex;
}

.content > .table > div {
  text-align: start;
  width: 32ch;
  min-width: 32ch;
}

.content > .table > img {
  width: 32ch;
  height: auto;
}

@media (width <= 768px) {
  .content > .table > div {
    text-align: justify;
    width: 90vw;
    max-width: 65ch;
  }

  .content > .table > img {
    display: none;
  }
}

.max-w-screen-xl {
  max-width: 1280px;
}

.text-small {
  font-size: 1.225rem;
  line-height: 1.75rem;
}

.text-medium {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-large {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-gray {
  color: #1f2937;
}

.text-lightgray {
  color: #888;
}

.text-black {
  color: #000;
}

@keyframes scroll-right {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.move_right {
  opacity: 0;
  animation: 1s both scroll-right;
  transform: translateX(-10%);
}

.move_right:nth-child(2) {
  animation-delay: .2s;
}

.move_right:nth-child(3) {
  animation-delay: .4s;
}

.move_right:nth-child(4) {
  animation-delay: .6s;
}

@keyframes fly-right {
  from {
    opacity: 0;
    transform: translateX(-10vw);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.text-slider {
  color: var(--sec-col-dark);
}

.text-slider span {
  display: none;
}

.text-slider:after {
  content: "Microsoft Excel";
  animation: 5s linear infinite spin;
}

@keyframes spin {
  0% {
    content: "Microsoft Excel";
  }

  25% {
    content: "Microsoft Word";
  }

  50% {
    content: "Google Sheets";
  }

  75% {
    content: "Google Docs";
  }
}

.strikethrough {
  text-decoration: line-through;
}

.underline {
  text-decoration-line: underline;
}

footer {
  background-color: #3c3e47;
  border: 1px gray;
  border-radius: 18px;
  flex-direction: row;
  place-content: center space-around;
  place-self: center;
  justify-items: center;
  width: 90vw;
  max-width: 1024px;
  margin: 1rem auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 2px gray;
}

footer .button {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

@media (width <= 400px) {
  footer {
    border-radius: 0;
    width: 100vw;
    margin-top: 0;
    margin-bottom: 0;
    padding: .5rem .25rem;
  }

  footer .button {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=index.9181388c.css.map */
